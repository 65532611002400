import React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { LayoutProvider, Wrapper } from '../components/layout';
import { ResponsiveContainer } from '../atoms/Container';
import { CannonBreadcrumbs } from '../atoms';
import { useTableOrMobile } from '../hooks';
import { cannonStyled, useCannonStyletron } from '../theme';
import { IFileImage } from '../@types';
import UserManualLocks from '../components/sections/UserManualLocks';
import ReplacementParts from '../components/sections/ReplacementParts';
import StepsForForm from '../components/sections/KeyAndCombinationForm/components/StepsForForm';
import TitleAndSubtitleAssitance from '../components/sections/KeyAndCombinationForm/components/TitleAndSubtitleAssitance';
import { RoundedButton } from '../atoms/Button';

export interface IData {
  strapiReplacementParts: StrapiReplacementParts;
}

export interface StrapiReplacementParts {
  title: string;
  subtitle: string;
  partsImage: PartsImage;
  partsImageNote: string;
  parts: Part[];
  paymentSuccessfulmage: IFileImage;
  paymentSuccessfulText: string;
  paymentSuccessfulTitle: string;
  safeModelInformationText: string;
  formDescription: string;
}

export interface Part {
  partName: string;
  quantity?: number;
  index?: number;
  price?: number;
}

export interface PartsImage {
  url: string;
}

// GRAPHQL QUERY
const query = graphql`
  query {
    strapiReplacementParts {
      title
      subtitle
      partsImageNote
      partsImage {
        url
      }
      parts {
        partName
        price
      }
      paymentSuccessfulmage {
        url
      }
      paymentSuccessfulText
      paymentSuccessfulTitle
      safeModelInformationText
      formDescription
    }
  }
`;

// STYLED COMPONENTS

const BreadCrumbsContainer = cannonStyled(
  'div',
  (props: { isMobile: boolean }) => ({
    position: 'absolute',
    top: props.isMobile ? '10px' : '30px',
    left: props.isMobile ? '16px' : '10px',
    width: '100%',
    textAlign: 'left',
  })
);

const MainWrapper = cannonStyled(
  Wrapper,
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: 'flex',
    maxWidth: '1280px',
    width: props.isMobile ? '95%' : props.isTablet ? '90%' : '100%',
    justifyContent: 'center',
    padding: props.isMobile ? '50px 0px 0px 0px' : '50px 0px 0px 0px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowX: 'hidden',
    textAlign: 'center',
  })
);

// COMPONENT
const ReplacementPartsPage: React.FC<PageProps> = (props: PageProps) => {
  // HOOKS
  console.log('query', query)
  const data = useStaticQuery<IData>(query);
  console.log('data', data)
  const [isMobile, isTablet] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();

  // STATE

  // HANDLERS & FUNCTIONS

  // EFFECTS & CALLBACKS

  // RENDER

  const { strapiReplacementParts = {} as StrapiReplacementParts } = data;
  
  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainWrapper isMobile={isMobile} isTablet={isTablet} noScroll>
        <BreadCrumbsContainer isMobile={isMobile}>
          <CannonBreadcrumbs
            pages={[
              { title: 'Home', href: '/' },
              { title: 'Support', href: '' },
              { title: 'Replacement Parts', href: `/replacement-parts` },
            ]}
          />
        </BreadCrumbsContainer>
        <ResponsiveContainer>
          <div style={{marginTop: '90px', marginBottom: '110px'}}>
            <TitleAndSubtitleAssitance
              title={strapiReplacementParts.title}
              subTitle={strapiReplacementParts.subtitle}
            />
            <StepsForForm
              steps={strapiReplacementParts.formDescription}
              note={'/'}
            />
            <RoundedButton
              onClick={() => {
                window.location.href =
                  'https://customersupport.cannonsafe.com/portal/en/newticket';
              }}
              clean
              color='secondary'
              size='compact'
              $style={{ margin: '80px 0', padding: '20px 50px' }}
            >
              Open a Ticket
            </RoundedButton>
          </div>
          {/* <ReplacementParts data={strapiReplacementParts} /> */}
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default ReplacementPartsPage;
